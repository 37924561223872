<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-extract :current="2"></sidebar-extract>

          <div class="page__content">
            <div class="header_transfer">
              <h1 class="page__title">Saldo coletores</h1>
              <form class="search__form">
                <input
                  class="form__input"
                  type="text"
                  placeholder="Pesquisar..."
                  @input="debounceInput"
                  v-model="search"
                />
                <button class="button search__button" type="submit">
                  <i class="icon icon-search"></i>
                </button>
              </form>
            </div>

            <div v-if="!loading" class="tab__body current" id="all">
              <table class="table table_transfer">
                <thead>
                  <tr>
                    <th>Coletor</th>
                    <th>Saldo</th>
                    <th>Opções</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="collector in pagedMaterials" :key="collector.id">
                    <td style="width: 300px;">
                      <div
                        class="circle_initial"
                        :style="{ 'background-color': '#16b666' }"
                      >
                        <p>{{ collector.name[0] }}</p>
                      </div>
                      <span> {{ collector.name }}</span>
                    </td>
                    <td>
                      <span>{{ collector.saldo | money }}</span>
                    </td>
                    <td style="text-align: right;">
                      <a :href="'acertos?collector_id=' + collector.id" type="button" class="button_outline transfer">
                        Acertos
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Loading v-else />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
export default {
  data: () => ({
    collectors: [],
    loading: true,
    search: '',
    filtered: []
  }),
  props: {
    current: Boolean
  },
  methods: {
    ...mapActions('monetary', { getValues: 'getData' }),
    async getData (values = false) {
      if (values) this.getValues()

      let url = '/financial/getBalanceUsers'
      if (this.search) url += `?search=${this.search}`
      setTimeout(() => (this.loading = true), 10)
      const { data } = await this.$http.post(url, { id: this.user.id })
      this.collectors = data.data.coletores
      this.filtered = this.collectors
      this.loading = false
    },
    debounceInput: function () {
      const search = this.search
      this.filtered = []
      this.filtered = _.flatMap(this.collectors).filter((value) => {
        return value.name.includes(search)
      })
    }
  },
  created () {
    this.getData()
  },
  watch: {
    search (val, old) {
      if (val === old) return null
      this.getData()
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    pagedMaterials: function () {
      let filtered = ''
      if (this.search !== '' && this.search) {
        filtered = _.flatMap(this.collectors).filter((value) => {
          return value.name.includes(this.search)
        })
      } else {
        filtered = this.collectors
      }
      return filtered
    }
  },
  components: {
    sidebarExtract: () => import('@/components/sidebar-extracts'),
    Loading: () => import('../../components/loading.vue')
  },
  filters: {
    money (val) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(parseFloat(val))
    }
  }
}
</script>

<style>
.header_transfer {
  display: flex;
  justify-content: space-between;
}

.table_transfer {
  margin-top: 45px;
}

.tabs__search {
  width: 40%;
}

.table {
  table-layout: fixed;
}

th,
td {
  font-weight: 400;
  width: 100%;
  text-align: left;
}

td span {
  font-weight: 400;
  font-size: 16px;
}

.table tbody tr td:nth-child(1) {
  width: 250px;
  display: inline-flex;
}

.table thead tr th:nth-child(3) {
  text-align: right;
  padding-right: 30px;
}

.table tbody tr td:nth-child(3) {
  text-align: center;
}

.circle_initial {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  margin-right: 7px;
  position: relative;
}

.circle_initial p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.transfer {
  color: #16b666;
}

.rescue {
  color: #ff371a;
  margin-left: 23px;
}

transfer,
.field__number label,
.field__number p {
  border: none;
  color: #16b666;
  text-transform: uppercase;
}
.transfer {
  text-transform: none;
}

.field__number input {
  margin-bottom: 5px;
  margin-top: 10px;
  padding-bottom: 10px;
}
.footer_modal {
  border-top: none;
}

.label-name {
  display: flex;
  height: 55px;
  width: 55px;
  background: #16b666;
  color: white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 15px;
  font-size: 28px;
}
</style>
